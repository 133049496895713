import React from "react";
import "./ServicesSection.css";
import Card from "./ServiceCard";
import serviceImage1 from "../images/service-icon-app-dev.png";
import serviceImage2 from "../images/service-icon-devops.png";
import serviceImage3 from "../images/service-icon-integration.png";

function ServicesSection() {
    return (
        <>
            <div className="service-cards-wrapper">
                <div className="service-cards-title">
                    <h2>
                        our services
                        <span className="fullstop">.</span>
                    </h2>
                </div>

                <div className="service-cards">
                    <Card
                        title="development"
                        image={serviceImage1}
                        link="/services#app-dev"
                    />
                    <Card
                        title="integration"
                        image={serviceImage3}
                        link="/services#integration"
                    />
                    <Card 
                        title="devops" 
                        image={serviceImage2} 
                        link="/services#devops" 
                    />
                </div>
            </div>
        </>
    );
}

export default ServicesSection;
