import React from "react";
import { Link } from "react-router-dom";
import "./ContactBanner.css";

function ContactBanner() {
  return (
    <>
      <div className="contact-banner-wrapper">
        <div className="contact-banner-content">
          <div className="contact-banner-text">hello@avari.technology</div>
          <div className="contact-banner-button">
            <Link to="/contact">
              <button>get in touch<span className="fullstop">.</span></button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactBanner;
