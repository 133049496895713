import React from "react";
import HeroSection from "../common/HeroSection";
import TeamSection from "./TeamSection";
import ContactBanner from "../common/ContactBanner";
import Clients from "./Clients";

function AboutPage() {
  return (
    <>
      <HeroSection
        title="about"
        text={`We’re a team of Salesforce experts with a deep understanding of business process and technology.`}
      />
      <TeamSection/>
      <Clients />
      <ContactBanner />

    </>
  );
}

export default AboutPage;
