import React from "react";
import "./Clients.css";
import gentrackLogo from "../images/gentrack-logo-white.png";
import purePlanetLogo from "../images/pure-planet-logo-white.png";

function Clients() {
  return (
    <>
        <div className="client-banner-wrapper">
            <div className="client-banner-content">
                <div className="client-banner-title">
                    <h2>
                        experience
                        <span className="fullstop">.</span>
                    </h2>
                </div>
                <div className="client-logos-wrapper">
                    <div className="client-logo">
                        <img src={gentrackLogo} alt="Gentrack" />
                    </div>
                    <div className="client-logo">
                        <img src={purePlanetLogo} alt="Pure Planet"/>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Clients;