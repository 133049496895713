import React from "react";
import HeroSection from "../common/HeroSection";
import ServicesSection from "./ServicesSection";
import HowSection from "./HowSection";
import ContactBanner from "../common/ContactBanner";

function HomePage() {
  return (
    <>
        <HeroSection
            title="salesforce development experts"
            text={`Enhance your business with our innovative solutions and development services, designed to maximise the potential of the Salesforce platform.`}
        />
        <ServicesSection />
        <HowSection />
        <ContactBanner />
    </>
  );
}

export default HomePage;
