import React from "react";
import Modal from "react-modal";
import "./TeamCard.css";

function TeamCard(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    overlay: {
      overflowY: "auto"
    },
  };

  return (
    <>
      <div className="team-member-wrapper" onClick={openModal}>
        <div className="team-member-image">
          <img src={props.image} alt={props.title} />
        </div>
        <div className="team-member-name">
          <h3>{props.name}</h3>
          <p>{props.title}</p>
        </div>
      </div>

      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel={props.name}
          className="team-member-modal"
          style={customStyles}
        >
          <div className="team-member-close-modal">
            <i className="fa-solid fa-xmark" onClick={closeModal}></i>
          </div>

          <h1>{props.name}</h1>
          <h2>{props.title}</h2>
          <div className="team-member-text">
            <p>{props.text}</p>
          </div>
         

          <div className="team-member-linkedin">
            <a href={props.linkedin} target="new">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default TeamCard;
