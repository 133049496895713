import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";


function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-text">
        <div className="footer-wrap">
          <Link to="/" className="footer-logo">
            <img src={logo} alt="logo" />
          </Link>
 
          <div className="footer-website-rights">
            avari technology ltd © {new Date().getFullYear()} | registered in england and wales 15379638
          </div>
          

          <div className="footer-social-icons">
            <Link
              className="footer-social-icon-link"
              to="mailto:hello@avari.technology"
              target="_blank"
              aria-label="Mail"
            >
              <i className="fa-solid fa-envelope" />
            </Link>
            <Link
              className="footer-social-icon-link"
              to="https://www.linkedin.com/company/avari-technology/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
