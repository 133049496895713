import React from "react";
import "./ContactDetails.css";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";

function ContactDetails() {
  return (
    <>
      <div className="contact-wrapper">
        
        <div className="contact-form">
          <ContactForm />
        </div>
      
        <div className="contact-details">
          <p>30 The Hollow</p>
          <p>Bath</p>
          <p>BA2 1LY</p>
          <br />
          <p><a href="mailto:hello@avari.technology">hello@avari.technology</a></p>
          <br />
          <Link
            className="contact-details-icon"
            to="https://www.linkedin.com/company/avari-technology/"
            target="_blank"
            aria-label="LinkedIn"
          >
            <i className="fab fa-linkedin" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default ContactDetails;
