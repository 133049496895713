import React from "react";
import './TeamSection.css';
import TeamCard from "./TeamCard";
import harryImage from "../images/bio-harry.jpg";
import lizzieImage from "../images/bio-lizzie.jpg";
import adamImage from "../images/bio-adam.jpg";

function TeamSection() {
  return (
    <>
      <div className="team-wrapper">

        <div className="team-banner-title">
          <h2>
              team
              <span className="fullstop">.</span>
          </h2>
        </div>
        <div className="team-cards">
          <TeamCard
            image={harryImage}
            name="Harry Williams"
            title="Co-Founder"
            text={`With a proven background in building and delivering technical solutions \
            for the charity and utilities sectors, Harry has experience in Salesforce development \
            and leveraging the full potential of the Salesforce platform. 

            Harry spent 5 years in the technology team of a Bath-based nonprofit charity before \
            transitioning to the Salesforce development team at the award-winning renewable energy \
            startup, Pure Planet. He has since worked at Gentrack, where he was part of the team that \
            spearheaded the development of a new Salesforce product with the aim of facilitating the \
            modern transformation of utilities.
            `}
            linkedin="https://www.linkedin.com/in/harry-l-williams/"
          />
          <TeamCard
            image={lizzieImage}
            name="Lizzie Murphy"
            title="Co-Founder"
            text={`Lizzie has worked with Salesforce for over 4 years and has experience as both an \
            administrator and developer. She started her career with the platform in 2020 at the energy \
            firm Pure Planet, where she delivered a wide range of automations and data transformation \
            projects to streamline business processes. 

            Lizzie has since worked at Gentrack as a developer, pioneering the company’s new Salesforce product. 
            
            When she’s not deep in development she can be found tearing up the netball court or on a long walk with Bob the dog. 
            `}
            linkedin="https://www.linkedin.com/in/lizziejamurphy/"
          />
          <TeamCard
            image={adamImage}
            name="Adam Eldridge"
            title="Co-Founder"
            text={`Adam is an experienced Salesforce developer and architect with over a decade of experience, \
                  specialising in the energy and renewables sector. He was part of the team behind award-winning \
                  energy startup Pure Planet, leading the development and integration of the Salesforce platform.

                  Adam has worked with Triodos Bank, Good Energy and more recently Gentrack to build out their \
                  CRM solution and establish them as a Salesforce partner.
            
                  He likes to tinker in his shed and take on DIY projects that are beyond his skill level.
            `}
            linkedin="https://www.linkedin.com/in/adameldridge/"
          />
        </div>
      </div>
    </>
  );
}

export default TeamSection;
