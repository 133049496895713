import React from "react";
import "./ServiceCard.css";
import { HashLink } from 'react-router-hash-link';


function ServiceCard(props) {
  return (
    <>
      <div className="card-wrapper">
        <HashLink style={{ textDecoration: 'none' }} to={props.link}>
          <div className="card-image">
            <img src={props.image} alt={props.title} />
          </div>
          <div className="card-title">
            <h3>
              {props.title}
            </h3>
          </div>
        </HashLink>
      </div>
    </>
  );
}

export default ServiceCard;
