import React from "react";
import HeroSection from "../common/HeroSection";
import ContactBanner from "../common/ContactBanner";
import Services from "./ServicesOverview";



function ServicesPage() {
  return (
    <>
      <HeroSection
        title="services"
        text={`Transform your business and bring your ideas to life on the Salesforce platform
              with our expert development services.`}/>
      <Services/>
      <ContactBanner />
    </>
  );
}

export default ServicesPage;
