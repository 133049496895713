import React from "react";
import "./HeroSection.css";

function Hero(props) {
  return (
    <div className="hero-container">
      <div className="hero-content">

        <div className="hero-text">
          <h1>{props.title}<span className="fullstop">.</span></h1>
          <p>{props.text}</p>
        </div>

        <div className="hero-graphics">
          <i className="fa-solid fa-terminal"></i>
        </div>

      </div>
    </div>
  );
}

export default Hero;
