import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logo.png";

function Navbar() {
  
  //Manage display of mobile menu
  const [mobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => setMobileMenu(!mobileMenu);
  const closeMobileMenu = () => setMobileMenu(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="logo" />
          </Link>

          <div className="navbar-menu-icon" onClick={handleMobileMenu}>
            <i className={mobileMenu ? "fas fa-times" : "fas fa-bars"} />
          </div>

          <ul className={mobileMenu ? "navbar-menu active" : "navbar-menu"}>
            <li className="navbar-item">
              <Link
                to="/services"
                className="navbar-links"
                onClick={closeMobileMenu}
              >
                services<span className="fullstop">.</span>
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/about" className="navbar-links" onClick={closeMobileMenu}>
                about<span className="fullstop">.</span>
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="navbar-links"
                onClick={closeMobileMenu}
              >
                contact<span className="fullstop">.</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
