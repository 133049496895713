import React from "react";
import HeroSection from "../common/HeroSection";
import ContactDetails from "./ContactDetails";

function ContactPage() {
  return (
    <>
      <HeroSection
        title="contact"
        text={`Drop us a message and one of our experts will be in touch to 
        discuss your Salesforce development needs.`}
      />
      <ContactDetails />
    </>
  );
}

export default ContactPage;
