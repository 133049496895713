import { useState } from 'react';
import './ContactForm.css';

function ContactForm () {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);

    async function createLead(){
        const oid = '00DQH000005ydwT';
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const company = document.getElementById('company').value;
        const message = document.getElementById('message').value;


        // Check form is populated
        if(!name || !email || !company || !message){
            setStateMessage('Error: please complete all fields');
            setIsSubmitting(false);
            return;
        }

        const options = {
            method: 'POST',
            mode: 'no-cors',
        };
    
        const baseUrl = 'https://webto.salesforce.com/servlet/servlet.WebToLead';
    
        const params = [
            `encoding=UTF-8`,
            `oid=${oid}`,
            `last_name=${name}`,
            `email=${email}`,
            `company=${company}`,
            `lead_source=Web`,
            `00NQH00000B9ZD9=${message}`
        ]
    
        const endpoint = `${baseUrl}?${params.join('&')}`;
        setIsSubmitting(true);

        try{
            await fetch(endpoint, options);
            setStateMessage('Message sent!');
            document.getElementById('contact-form').reset();
        }catch(e){
            setStateMessage('Something went wrong, please try again later'); 
        }

        setIsSubmitting(false);
        setTimeout(() => {
            setStateMessage(null);
        }, 3000);
        
    }

    return (
      <form id="contact-form">
        <label className="contact-form-label">name<span className="fullstop">.</span></label>
        <input className="contact-form-input" type="text" id="name" />
        
        <label className="contact-form-label">email<span className="fullstop">.</span></label>
        <input className="contact-form-input" type="email" id="email" />

        <label className="contact-form-label">company<span className="fullstop">.</span></label>
        <input className="contact-form-input" type="text" id="company" />
        
        <label className="contact-form-label">message<span className="fullstop">.</span></label>
        <textarea className="contact-form-message" id="message" />
        <div className="g-recaptcha" data-sitekey="6LdNEVgpAAAAAAVzz2B0O0cyLq1NUSE_GJf5EVme"></div>

        <input className="contact-form-button" type="button" value="send" onClick={createLead} disabled={isSubmitting}/>
        {stateMessage && <p>{stateMessage}</p>}
      </form>
    );
  };
  export default ContactForm;