import React from "react";
import "./HowSection.css";
import HowCard from "./HowCard";

function HowSection() {
    return (
      <>
        <div className="howsection-wrapper">
          <div className="howsection-title">
            <h2>
                how we work
                <span className="fullstop">.</span>
            </h2>
          </div>
          <div className="howsection-cards">
            <HowCard
                title="build"
                text="We design, build and deliver your solution"
            />
            <HowCard
                title="enhance"
                text="We integrate with your existing team"
            />
            <HowCard 
                title="support"
                text="We’re available when you need extra help"
            />
          </div>
        </div>

  
      </>
    );
  }
  
  export default HowSection;