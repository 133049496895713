import React from 'react';
import ServiceDetail from "./ServiceDetail";
import serviceImage1 from "../images/service-icon-app-dev.png";
import serviceImage2 from "../images/service-icon-devops.png";
import serviceImage3 from "../images/service-icon-integration.png";

function Services() {


  return (
    <>
      <ServiceDetail
        image={serviceImage1}
        title="development"
        id="app-dev"
        text={`Our custom Salesforce app development services provide you flexible, scalable solutions to improve \
              the efficiency of your business processes. 

              With our team’s certified expertise in Salesforce development, we tailor-make custom Salesforce \
              applications specifically designed to meet the needs of your business.
        
              Using our iterative development process, our certified developers specialise in employing \
              Salesforce best practices whilst leveraging the full potential of the Salesforce platform to 
              create user-friendly, intuitive apps to streamline your business processes.    
        `}
      />

      <ServiceDetail
        image={serviceImage3}
        title="integration"
        id="integration"
        text={`We provide system integration services tailored specifically \
              for Salesforce. We build customised solutions to seamlessly integrate Salesforce with your existing systems, \
              ensuring it aligns perfectly with your business objectives. 

              We have a wealth of experience in facilitating data exchange between Salesforce and \
              third party apps to ensure seamless communication across your business ecosystem. As your business \
              grows and evolves, so do your integration needs. Our solutions are designed with scalability and \
              flexibility in mind, allowing you to adapt and expand your Salesforce ecosystem effortlessly.
        `}
      />

      <ServiceDetail
        image={serviceImage2}
        title="devops"
        id="devops"
        text={`We offer comprehensive DevOps services tailored to streamline your \
              processes and optimise your Salesforce development lifecycle. Our goal \
              is to facilitate the rapid delivery of new features and enhancements. \
              By integrating the latest Salesforce technology with DevOps best practices, \
              we can modernise your existing delivery process or kickstart a new project.
        
              Our approach includes continuous integration and deployment (CI/CD), automated \
              testing, and efficient environment management. These practices are designed to \
              accelerate delivery cycles and enhance overall quality. 
              
              We're dedicated to enhancing your Salesforce development journey by providing efficient, \
              reliable, and innovative DevOps solutions.
        `}
      />
    </>
  );
}

export default Services;
