import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./common/Navbar";
import HomePage from "./home/HomePage";
import ServicesPage from "./services/ServicesPage";
import AboutPage from "./about/AboutPage";
import ContactPage from "./contact/ContactPage";
import Footer from "./common/Footer";
import NotFoundPage from "./404/NotFoundPage";

function App() {
  return (
    <>
      <Router>
        <header>
          <Navbar />
        </header>
        <main>
          <Routes>
            <Route path="/" exact element={<HomePage />}></Route>
            <Route path="/services" exact element={<ServicesPage />}></Route>
            <Route path="/about" exact element={<AboutPage />}></Route>
            <Route path="/contact" exact element={<ContactPage />}></Route>
            <Route path="*" exact element={<NotFoundPage />}></Route>
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </Router>
    </>
  );
}

export default App;
