import React from "react";
import "./HowCard.css";


function HowCard(props) {
  return (
    <>
      <div className="howcard-wrapper">
          <div className="howcard-title">
            <h3>
              {props.title}
            </h3>
          </div>
          <div className="howcard-text">
            <p>{props.text}</p>
          </div>
      </div>
    </>
  );
}

export default HowCard;
