import React from "react";
import "./ServiceDetail.css";

function ServiceSection(props) {
  return (
    <>
      <div 
        className="service-section-wrapper" 
        id={props.id}
      >
        <div className="service-section-image">
          <img src={props.image} alt={props.title} />
        </div>

        <div className="service-section-content">
          <div className="service-section-title">
            <h2>
              {props.title}
              <span className="fullstop">.</span>
            </h2>
          </div>

          <div className="service-section-text">
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceSection;
